import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    MDBFooter,
    MDBIcon,
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdb-react-ui-kit";
import "./footer.css"
import { BASE_URL } from "../../axios/config";
import { Slide, Fade } from 'react-reveal';
import { getFooterAll, get_solution_links } from "../../axios/Api";
import { Alert, Snackbar } from "@mui/material";
//创建动画

const vertical = 'top';
const horizontal = 'center';
export default class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            copyYear: new Date().getFullYear(),
            company_name: '',
            company_des: "",
            company_icp: "",
            company_address: "",
            company_email: "",
            company_phone: "",
            company_qr: "",
            company_sqr: "",
            company_slink: "",
            is_show: 'block',
            solution_links: []
        };
        this.handleResize = this.handleResize.bind(this);
    }
    sendLogo = (url) => {
        //通过props属性获取父组件的getlogo方法，并将this.state值传递过去
        this.props.getlogo(url);
    }
    getFooterInfo = async () => {
        getFooterAll().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    company_name: res.data.data.site_name,
                    company_des: res.data.data.site_des,
                    company_icp: res.data.data.site_icp,
                    company_address: res.data.data.site_address,
                    company_email: res.data.data.site_email,
                    company_phone: res.data.data.site_phone,
                    company_qr: BASE_URL + res.data.data.site_wxqr,
                    company_sqr: BASE_URL + res.data.data.site_serverqr,
                    company_slink: res.data.data.site_server_link,
                    company_right: res.data.data.site_copyright,
                    show_alert: false,
                    show_alert_tips: ''
                });
                this.sendLogo([res.data.data.site_logo, res.data.data.login_link, res.data.data.site_wxqr, res.data.data.site_serverqr]);
            }
        }).catch(error => {
            if (error.code === "ECONNABORTED") {
                this.setState({
                    show_alert: true,
                    show_alert_tips: '远程服务器不可用，请检查！'
                })
            } else if (error.code === "ETIMEDOUT") {
                this.setState({
                    show_alert: true,
                    show_alert_tips: '请求超时，请稍后再试！'
                })
            } else {
                this.setState({
                    show_alert: true,
                    show_alert_tips: '网络异常，请检查网络连接是否正常！'
                })
            }
        });

    }
    getSolutionLink = async () => {
        get_solution_links().then(res => {
            if (res.data.code === 200) {
                this.setState({
                    solution_links: res.data.data
                })
            }
        }).catch(error => {

        })
    }
    componentDidMount() {
        this.getFooterInfo();
        this.getSolutionLink();
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }
    componentWillUnmount() {
        window.addEventListener('resize', this.handleResize);
    }
    //监听屏幕变化
    handleResize() {
        if (
            //判断移动端隐藏登录及注册按钮
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i)
        ) {
            this.setState({
                is_show: 'none',
            });
        } else {
            this.setState({
                is_show: 'block',
            });
        }
    }
    //页脚公共组件
    render() {
        return (
            <>
                <MDBFooter className='text-center text-lg-start text-muted diy-footer'>
                    <section className='d-flex'>
                        <MDBContainer className='text-center text-md-start mt-2 diy-text-left'>
                            <MDBRow className='mt-2'>
                                <MDBCol style={{ display: this.state.is_show }} md='3' lg='3' xl='3' className='mx-auto mb-2 diy-footer-col'>
                                    <div style={{ width: '100%' }}>
                                        <section className="text-center">
                                            <MDBRow>
                                                <MDBCol>
                                                    <Slide top cascade>
                                                        <img
                                                            src={this.state.company_qr}
                                                            className='img-thumbnail'
                                                            alt='微信公众号'
                                                        />
                                                    </Slide>
                                                    <Slide bottom cascade>
                                                        <p className="qr-tips-text">微信公众号</p>
                                                    </Slide>
                                                </MDBCol>
                                                <MDBCol>
                                                    <Slide top cascade>
                                                        <img
                                                            src={this.state.company_sqr}
                                                            className='img-thumbnail'
                                                            alt='客服二维码'
                                                        />
                                                    </Slide>
                                                    <Slide bottom cascade>
                                                        <p className="qr-tips-text">客服二维码</p>
                                                    </Slide>
                                                </MDBCol>
                                            </MDBRow>
                                        </section>
                                    </div>
                                </MDBCol>
                                {/* <MDBCol md='3' lg='3' xl='3' className='mx-auto mb-2 diy-footer-col'>
                                    <Zoom right>
                                        <h6 className='text-uppercase fw-bold mb-2'>
                                            <MDBIcon color='secondary' fas icon="angle-double-right" className='me-3' />
                                            {this.state.company_name}
                                        </h6>
                                    </Zoom>
                                    <Slide bottom>
                                        <p className="diy-footer-company-des">
                                        {this.state.company_des}
                                        </p>
                                    </Slide>
                                </MDBCol> */}

                                <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-2 diy-footer-col'>
                                    <Fade bottom>
                                        <h6 className='text-uppercase fw-bold mb-2'>
                                            <MDBIcon color='secondary' fas icon="book" className='me-3' />
                                            解决方案
                                        </h6>
                                    </Fade>
                                    {
                                        this.state.solution_links.map((item, index) => {
                                            return (
                                                (
                                                    <Fade bottom cascade key={index}>
                                                        <p key={item.id}>
                                                            {/* <a href={item.link} className='text-reset'>{item.name}</a> */}
                                                            <Link key={item.id} to={item.link}>{item.name}</Link>
                                                        </p>
                                                    </Fade>
                                                )
                                            )
                                        })
                                    }
                                </MDBCol>
                                <MDBCol md='4' lg='4' xl='4' className='mx-auto mb-2 diy-footer-col'>
                                    <Fade bottom>
                                        <h6 className='text-uppercase fw-bold mb-2'>
                                            <MDBIcon color='secondary' fas icon="comments" className='me-3' />
                                            联系我们
                                        </h6>
                                    </Fade>
                                    <Fade bottom>
                                        <p>
                                            <MDBIcon color='secondary' icon='landmark' className='me-3' />
                                            北京市朝阳区北苑路乙108号34幢
                                        </p>
                                    </Fade>
                                    <Fade bottom cascade>
                                        <div className="diy-footer-div">
                                            <p>
                                                <MDBIcon color='secondary' icon='home' className='me-3' />
                                                {this.state.company_address}
                                            </p>
                                            <p>
                                                <MDBIcon color='secondary' icon='envelope' className='me-3' />
                                                {this.state.company_email}
                                            </p>
                                            <p>
                                                <MDBIcon color='secondary' icon='phone' className='me-3' />{this.state.company_phone}
                                            </p>
                                        </div>
                                    </Fade>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </section>
                    <div className='text-center p-2 foot-icp' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', color: '#bdbdbd !important' }}>
                        <a className='text-reset' href='https://beian.miit.gov.cn'>
                            {this.state.company_icp}
                        </a>
                        &nbsp;©&nbsp;<span>{this.state.copyYear}</span>&nbsp;Copyright&nbsp;{this.state.company_right}&nbsp;All Rights Reserved
                    </div>
                </MDBFooter>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={this.state.show_alert}
                    message={this.state.show_alert_tips}
                    key={vertical + horizontal}
                >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {this.state.show_alert_tips}
                    </Alert>
                </Snackbar>
            </>
        )
    }
}