import React from 'react';
import Chat, { Bubble, useMessages } from "@chatui/core";
import '@chatui/core/dist/index.css';
import axios from 'axios';
import custom from "../../assets/aichat.png";
import bot from "../../assets/bot.png";
import hisRecord from "../../assets/record.png";
import { get_chat_top3,insert_chat_data } from '../../axios/Api';
import md5 from 'js-md5';
const apiURL = 'https://gateway.tiaoyuezhineng.com/remote_ivl/qpivy/chat';
const initialMessages = [
  {
    type: 'text',
    content: { text: '你好，请输入想要了解的问题！' },
    user: { avatar: bot },
  }
];

export default function AIChat() {
  // 消息列表
  const { messages, appendMsg, setTyping } = useMessages(initialMessages);

    //生成签名
    const r_time = new Date().getTime().toString();
    const request_id = md5(r_time);
    const version = '1.0.0';
    const s = request_id + r_time + version;
    const signature = md5(s);
  //默认加载最新5条记录
  // function getTopFive(){
  //   get_chat_top5().then((res) => {
  //     if(res.data.code === 200){
  //       if(res.data.data.length > 0){
  //         for(let i = 0; i < res.data.data.length; i++){
  //           appendMsg({
  //             type: 'text',
  //             content: { text: res.data.data[i].content },
  //             position: 'right',
  //             user: { avatar: custom }
  //           });
  //           setTyping(true);
  //         }
  //       }
  //     }
      
  //   }).catch((error) => {

  //   })
  // }

  //调用接口
  function handleSend(type, val) {
    let elements = document.getElementsByClassName('left');
    for (var i = 0; i < elements.length; i++) {
      var element = elements[i];
      element.parentNode.removeChild(element); // 或者直接使用 remove() 方法也行
  }
    //执行
    get_chat_top3().then((res) => {
      if(res.data.code === 200){
        if(res.data.data.length > 0){
          for(let i = 0; i < res.data.data.length; i++){
            appendMsg({
              type: 'text',
              content: { text: res.data.data[i].content },
              position: 'left',
              user: { avatar: hisRecord }
            });
            setTyping(true);
          }
        }
      }
      
    if (type === 'text' && val.trim()) {
      appendMsg({
        type: 'text',
        content: { text: val },
        position: 'right',
        hasTime:true,
        user: { avatar: custom }
      });
      setTyping(true);
        axios({
          url: apiURL,
          method: 'POST',
          headers: {
              'version':'1.0.0',
              'requestid':request_id,
              'requesttime':r_time,
              'signature':signature,
              'Content-Type': 'application/json'
          },
          data: {
              message: val
          }
      })
        .then((res) => {
          if (res.status === 200) {
            let data = {
                content:val
            }
            insert_chat_data(data).then((res) => {
            }).catch((error) => {

            })
          }
        }).catch((error) => {
          console.log('接口请求出错');
        });
    }
    }).catch((error) => {

    });
  }
  function renderMessageContent(msg) {
    const { type, content } = msg;

    // 根据消息类型来渲染
    switch (type) {
      case 'text':
        return <Bubble content={content.text} />;
      case 'image':
        return (
          <Bubble type="image">
            <img src={content.picUrl} alt="" />
          </Bubble>
        );
      default:
        return null;
    }
  }

  return (
    <Chat
      navbar={{ title: '云数字人' }}
      wideBreakpoint="600px"
      messages={messages}
      renderMessageContent={renderMessageContent}
      // quickReplies={defaultQuickReplies}
      // onQuickReplyClick={handleQuickReplyClick}
      onSend={handleSend}
    />
  );
}